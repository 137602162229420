export const chartDataForAccidentSeverityByYear = [
  {
    year: '2014',
    fatal: 2,
    suspected_serious_injury: 4,
    suspected_minor_injury: 20,
    possible_injury: 19,
    no_injury: 112,
    total: 157,
  },
  {
    year: '2015',
    fatal: 3,
    suspected_serious_injury: 3,
    suspected_minor_injury: 33,
    possible_injury: 31,
    no_injury: 117,
    total: 187,
  },
  {
    year: '2016',
    fatal: 3,
    suspected_serious_injury: 7,
    suspected_minor_injury: 42,
    possible_injury: 38,
    no_injury: 162,
    total: 252,
  },
  {
    year: '2017',
    fatal: 5,
    suspected_serious_injury: 1,
    suspected_minor_injury: 35,
    possible_injury: 35,
    no_injury: 151,
    total: 227,
  },
  {
    year: '2018',
    fatal: 2,
    suspected_serious_injury: 8,
    suspected_minor_injury: 36,
    possible_injury: 34,
    no_injury: 196,
    total: 276,
  },
  {
    year: '2019',
    fatal: 4,
    suspected_serious_injury: 7,
    suspected_minor_injury: 42,
    possible_injury: 37,
    no_injury: 228,
    total: 318,
  },
  {
    year: '2020',
    fatal: 5,
    suspected_serious_injury: 8,
    suspected_minor_injury: 31,
    possible_injury: 40,
    no_injury: 173,
    total: 257,
  },
  {
    year: '2021',
    fatal: 2,
    suspected_serious_injury: 3,
    suspected_minor_injury: 41,
    possible_injury: 45,
    no_injury: 189,
    total: 280,
  },
  {
    year: '2022',
    fatal: 8,
    suspected_serious_injury: 4,
    suspected_minor_injury: 41,
    possible_injury: 49,
    no_injury: 198,
    total: 300,
  },
  {
    year: '2023',
    fatal: 1,
    suspected_serious_injury: 4,
    suspected_minor_injury: 42,
    possible_injury: 44,
    no_injury: 179,
    total: 270,
  },
]

export const accidentData = {
  '2014': [
    { accidentType: 'Fatal', accidents: 2, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 4,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 20,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 19, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 112, fill: 'var(--color-no_injury)' },
  ],
  '2015': [
    { accidentType: 'Fatal', accidents: 3, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 3,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 33,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 31, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 117, fill: 'var(--color-no_injury)' },
  ],
  '2016': [
    { accidentType: 'Fatal', accidents: 3, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 7,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 42,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 38, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 162, fill: 'var(--color-no_injury)' },
  ],
  '2017': [
    { accidentType: 'Fatal', accidents: 5, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 1,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 35,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 35, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 151, fill: 'var(--color-no_injury)' },
  ],
  '2018': [
    { accidentType: 'Fatal', accidents: 2, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 8,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 36,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 34, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 196, fill: 'var(--color-no_injury)' },
  ],
  '2019': [
    { accidentType: 'Fatal', accidents: 4, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 7,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 42,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 37, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 228, fill: 'var(--color-no_injury)' },
  ],
  '2020': [
    { accidentType: 'Fatal', accidents: 5, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 8,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 31,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 40, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 173, fill: 'var(--color-no_injury)' },
  ],
  '2021': [
    { accidentType: 'Fatal', accidents: 2, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 3,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 41,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 45, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 189, fill: 'var(--color-no_injury)' },
  ],
  '2022': [
    { accidentType: 'Fatal', accidents: 8, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 4,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 41,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 49, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 198, fill: 'var(--color-no_injury)' },
  ],
  '2023': [
    { accidentType: 'Fatal', accidents: 1, fill: 'var(--color-fatal)' },
    {
      accidentType: 'Suspected Serious Injury',
      accidents: 4,
      fill: 'var(--color-suspected_serious_injury)',
    },
    {
      accidentType: 'Suspected Minor Injury',
      accidents: 42,
      fill: 'var(--color-suspected_minor_injury)',
    },
    { accidentType: 'Possible Injury', accidents: 44, fill: 'var(--color-possible_injury)' },
    { accidentType: 'No Injury', accidents: 179, fill: 'var(--color-no_injury)' },
  ],
}

export const fatalityData = [
  { year: '2014', fatalities: 2 },
  { year: '2015', fatalities: 3 },
  { year: '2016', fatalities: 5 },
  { year: '2017', fatalities: 8 },
  { year: '2018', fatalities: 3 },
  { year: '2019', fatalities: 4 },
  { year: '2020', fatalities: 5 },
  { year: '2021', fatalities: 3 },
  { year: '2022', fatalities: 9 },
  { year: '2023', fatalities: 1 },
]

export const injuryData = [
  { year: '2014', injuries: 60 },
  { year: '2015', injuries: 112 },
  { year: '2016', injuries: 146 },
  { year: '2017', injuries: 94 },
  { year: '2018', injuries: 113 },
  { year: '2019', injuries: 143 },
  { year: '2020', injuries: 119 },
  { year: '2021', injuries: 133 },
  { year: '2022', injuries: 143 },
  { year: '2023', injuries: 133 },
]
