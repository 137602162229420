'use client'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { usePlausible } from 'next-plausible'
import { SendIcon } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from '@components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'

const schema = z.object({
  name: z.string().min(2, { message: 'Please enter your name' }),
  email: z.string().email({ message: 'Please enter a valid email' }),
  message: z
    .string()
    .min(1, { message: 'Please enter a message' })
    .max(500, { message: 'Please enter a shorter message' })
    .optional(),
  subject: z.string().optional(),
  defaultMessage: z.any().optional(),
})

type FormValues = z.infer<typeof schema>

export function SmallContactForm() {
  const defaultValues: Partial<FormValues> = {
    name: '',
    email: '',
  }
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'onChange',
  })

  const plausible = usePlausible()

  async function onSubmit(data: FormValues) {
    await fetch('/api/send', {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        toast.success('Your message has been sent!', {
          duration: 5000,
          description:
            'Your message about SR347 to ADOT and elected officials has been sent successfully. Thank you for your active engagement!',
        })
        form.reset({
          name: '',
          email: '',
          message: '',
        })
        plausible('ContactFormSubmitted')
      } else {
        toast.error('Message failed to send', {
          duration: 5000,
          description: 'There was an error sending your message. Please try again later.',
        })
      }
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="John Doe" type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" placeholder="hello@example.com" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Voice Your Concerns about SR 347</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Share your thoughts on SR347 improvements, remember to be respectful..."
                  className="h-36 max-h-96 resize-none md:h-52"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {500 - (field.value?.length || 0)} characters remaining
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={form.formState.isSubmitting || !form.formState.isValid}>
          Send
          <SendIcon className="ml-2 h-4 w-4" />
        </Button>
      </form>
    </Form>
  )
}
