'use client'

import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { chartDataForAccidentSeverityByYear as chartData } from '@lib/chart-data'

const chartConfig = {
  total: {
    label: 'Accidents',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig

export function AccidentsByYear() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Accidents along SR-347</CardTitle>
        <CardDescription>
          A total of 2,524 accidents occurred on SR-347 between January 1, 2014 - December 31, 2023
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <YAxis tickLine={false} axisLine={false} />
            <XAxis dataKey="year" tickLine={false} tickMargin={10} axisLine={false} />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Bar dataKey="total" fill="var(--color-total)" radius={4}>
              <LabelList position="top" offset={12} className="fill-foreground" fontSize={12} />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="text-sm italic text-muted-foreground">
        Data collected from the Arizona Department of Transportation (ADOT) via a public records
        request.
      </CardFooter>
    </Card>
  )
}
