export const accidentsByYearByMonth = {
  '2014': [
    {
      month: 'January',
      accidents: 13,
    },
    {
      month: 'February',
      accidents: 15,
    },
    {
      month: 'March',
      accidents: 7,
    },
    {
      month: 'April',
      accidents: 5,
    },
    {
      month: 'May',
      accidents: 11,
    },
    {
      month: 'June',
      accidents: 12,
    },
    {
      month: 'July',
      accidents: 12,
    },
    {
      month: 'August',
      accidents: 17,
    },
    {
      month: 'September',
      accidents: 10,
    },
    {
      month: 'October',
      accidents: 16,
    },
    {
      month: 'November',
      accidents: 15,
    },
    {
      month: 'December',
      accidents: 24,
    },
  ],
  '2015': [
    {
      month: 'January',
      accidents: 19,
    },
    {
      month: 'February',
      accidents: 21,
    },
    {
      month: 'March',
      accidents: 17,
    },
    {
      month: 'April',
      accidents: 11,
    },
    {
      month: 'May',
      accidents: 10,
    },
    {
      month: 'June',
      accidents: 18,
    },
    {
      month: 'July',
      accidents: 12,
    },
    {
      month: 'August',
      accidents: 16,
    },
    {
      month: 'September',
      accidents: 8,
    },
    {
      month: 'October',
      accidents: 16,
    },
    {
      month: 'November',
      accidents: 25,
    },
    {
      month: 'December',
      accidents: 14,
    },
  ],
  '2016': [
    {
      month: 'January',
      accidents: 23,
    },
    {
      month: 'February',
      accidents: 22,
    },
    {
      month: 'March',
      accidents: 15,
    },
    {
      month: 'April',
      accidents: 16,
    },
    {
      month: 'May',
      accidents: 16,
    },
    {
      month: 'June',
      accidents: 24,
    },
    {
      month: 'July',
      accidents: 18,
    },
    {
      month: 'August',
      accidents: 11,
    },
    {
      month: 'September',
      accidents: 29,
    },
    {
      month: 'October',
      accidents: 28,
    },
    {
      month: 'November',
      accidents: 17,
    },
    {
      month: 'December',
      accidents: 33,
    },
  ],
  '2017': [
    {
      month: 'January',
      accidents: 21,
    },
    {
      month: 'February',
      accidents: 30,
    },
    {
      month: 'March',
      accidents: 25,
    },
    {
      month: 'April',
      accidents: 7,
    },
    {
      month: 'May',
      accidents: 15,
    },
    {
      month: 'June',
      accidents: 15,
    },
    {
      month: 'July',
      accidents: 11,
    },
    {
      month: 'August',
      accidents: 22,
    },
    {
      month: 'September',
      accidents: 18,
    },
    {
      month: 'October',
      accidents: 25,
    },
    {
      month: 'November',
      accidents: 15,
    },
    {
      month: 'December',
      accidents: 23,
    },
  ],
  '2018': [
    {
      month: 'January',
      accidents: 17,
    },
    {
      month: 'February',
      accidents: 23,
    },
    {
      month: 'March',
      accidents: 20,
    },
    {
      month: 'April',
      accidents: 23,
    },
    {
      month: 'May',
      accidents: 21,
    },
    {
      month: 'June',
      accidents: 18,
    },
    {
      month: 'July',
      accidents: 21,
    },
    {
      month: 'August',
      accidents: 28,
    },
    {
      month: 'September',
      accidents: 22,
    },
    {
      month: 'October',
      accidents: 25,
    },
    {
      month: 'November',
      accidents: 32,
    },
    {
      month: 'December',
      accidents: 26,
    },
  ],
  '2019': [
    {
      month: 'January',
      accidents: 36,
    },
    {
      month: 'February',
      accidents: 22,
    },
    {
      month: 'March',
      accidents: 25,
    },
    {
      month: 'April',
      accidents: 26,
    },
    {
      month: 'May',
      accidents: 28,
    },
    {
      month: 'June',
      accidents: 24,
    },
    {
      month: 'July',
      accidents: 11,
    },
    {
      month: 'August',
      accidents: 32,
    },
    {
      month: 'September',
      accidents: 24,
    },
    {
      month: 'October',
      accidents: 29,
    },
    {
      month: 'November',
      accidents: 29,
    },
    {
      month: 'December',
      accidents: 32,
    },
  ],
  '2020': [
    {
      month: 'January',
      accidents: 33,
    },
    {
      month: 'February',
      accidents: 19,
    },
    {
      month: 'March',
      accidents: 21,
    },
    {
      month: 'April',
      accidents: 15,
    },
    {
      month: 'May',
      accidents: 26,
    },
    {
      month: 'June',
      accidents: 15,
    },
    {
      month: 'July',
      accidents: 10,
    },
    {
      month: 'August',
      accidents: 13,
    },
    {
      month: 'September',
      accidents: 21,
    },
    {
      month: 'October',
      accidents: 31,
    },
    {
      month: 'November',
      accidents: 18,
    },
    {
      month: 'December',
      accidents: 35,
    },
  ],
  '2021': [
    {
      month: 'January',
      accidents: 28,
    },
    {
      month: 'February',
      accidents: 20,
    },
    {
      month: 'March',
      accidents: 31,
    },
    {
      month: 'April',
      accidents: 23,
    },
    {
      month: 'May',
      accidents: 25,
    },
    {
      month: 'June',
      accidents: 18,
    },
    {
      month: 'July',
      accidents: 23,
    },
    {
      month: 'August',
      accidents: 19,
    },
    {
      month: 'September',
      accidents: 21,
    },
    {
      month: 'October',
      accidents: 21,
    },
    {
      month: 'November',
      accidents: 27,
    },
    {
      month: 'December',
      accidents: 24,
    },
  ],
  '2022': [
    {
      month: 'January',
      accidents: 23,
    },
    {
      month: 'February',
      accidents: 28,
    },
    {
      month: 'March',
      accidents: 29,
    },
    {
      month: 'April',
      accidents: 20,
    },
    {
      month: 'May',
      accidents: 20,
    },
    {
      month: 'June',
      accidents: 19,
    },
    {
      month: 'July',
      accidents: 25,
    },
    {
      month: 'August',
      accidents: 33,
    },
    {
      month: 'September',
      accidents: 29,
    },
    {
      month: 'October',
      accidents: 23,
    },
    {
      month: 'November',
      accidents: 16,
    },
    {
      month: 'December',
      accidents: 35,
    },
  ],
  '2023': [
    {
      month: 'January',
      accidents: 21,
    },
    {
      month: 'February',
      accidents: 30,
    },
    {
      month: 'March',
      accidents: 19,
    },
    {
      month: 'April',
      accidents: 12,
    },
    {
      month: 'May',
      accidents: 14,
    },
    {
      month: 'June',
      accidents: 27,
    },
    {
      month: 'July',
      accidents: 17,
    },
    {
      month: 'August',
      accidents: 22,
    },
    {
      month: 'September',
      accidents: 17,
    },
    {
      month: 'October',
      accidents: 27,
    },
    {
      month: 'November',
      accidents: 36,
    },
    {
      month: 'December',
      accidents: 28,
    },
  ],
}

export const accidentsByTheHour = [
  { hour: '00:00', accidents: 22 },
  { hour: '01:00', accidents: 22 },
  { hour: '02:00', accidents: 22 },
  { hour: '03:00', accidents: 24 },
  { hour: '04:00', accidents: 38 },
  { hour: '05:00', accidents: 130 },
  { hour: '06:00', accidents: 213 },
  { hour: '07:00', accidents: 198 },
  { hour: '08:00', accidents: 118 },
  { hour: '09:00', accidents: 83 },
  { hour: '10:00', accidents: 80 },
  { hour: '11:00', accidents: 106 },
  { hour: '12:00', accidents: 127 },
  { hour: '13:00', accidents: 141 },
  { hour: '14:00', accidents: 152 },
  { hour: '15:00', accidents: 208 },
  { hour: '16:00', accidents: 189 },
  { hour: '17:00', accidents: 173 },
  { hour: '18:00', accidents: 153 },
  { hour: '19:00', accidents: 95 },
  { hour: '20:00', accidents: 81 },
  { hour: '21:00', accidents: 60 },
  { hour: '22:00', accidents: 49 },
  { hour: '23:00', accidents: 40 },
]

export const fatalitiesAndInjuries = [
  { year: '2014', fatalities: 2, injuries: 60 },
  { year: '2015', fatalities: 3, injuries: 112 },
  { year: '2016', fatalities: 5, injuries: 146 },
  { year: '2017', fatalities: 8, injuries: 94 },
  { year: '2018', fatalities: 3, injuries: 113 },
  { year: '2019', fatalities: 4, injuries: 143 },
  { year: '2020', fatalities: 5, injuries: 119 },
  { year: '2021', fatalities: 3, injuries: 133 },
  { year: '2022', fatalities: 9, injuries: 143 },
  { year: '2023', fatalities: 1, injuries: 133 },
]
