'use client'

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { fatalityData } from '@/lib/chart-data'

const chartConfig = {
  fatalities: {
    label: 'Fatalities',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig

export function Fatalities() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Yearly Fatalities Along SR-347</CardTitle>
        <CardDescription>
          Fatalities on SR-347 show significant fluctuations, peaking at 8 in 2017 and 9 in 2022,
          with the lowest at 1 in 2023.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={fatalityData}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="year" tickLine={false} tickMargin={10} axisLine={false} />
            <YAxis tickLine={false} axisLine={false} />
            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
            <Bar dataKey="fatalities" fill="var(--color-fatalities)" radius={4} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="text-sm italic text-muted-foreground">
        Data collected from the Arizona Department of Transportation (ADOT) via a public records
        request.
      </CardFooter>
    </Card>
  )
}
