'use client'

import { SanityImage } from 'sanity-image'

import { projectId, dataset } from '@lib/sanity.api'

type ImageComponentProps = {
  image: any
  width: number
  height: number
  className?: string
  mode?: 'cover' | 'contain'
}

const ImageComponent = ({
  image,
  width,
  height,
  className,
  mode = 'contain',
}: ImageComponentProps) => {
  return (
    <SanityImage
      projectId={projectId}
      dataset={dataset}
      id={image.asset._ref}
      alt={image.caption}
      title={image.caption}
      width={width}
      height={height}
      mode={mode}
      className={className}
    />
  )
}

export default ImageComponent
