'use client'

import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { accidentsByTheHour } from '@/data/accidents'

const chartConfig = {
  accidents: {
    label: 'Accidents',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig

export function AccidentsByHour() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Accidents on SR-347 by Hour</CardTitle>
        <CardDescription>
          Accidents by hour along SR-347 between January 1, 2014 - December 31, 2023
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
          <AreaChart
            accessibilityLayer
            data={accidentsByTheHour}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="hour"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => {
                let hour = parseInt(value)
                let amPm = hour >= 12 ? 'p.m.' : 'a.m.'
                hour = hour % 12
                hour = hour ? hour : 12
                return `${hour} ${amPm}`
              }}
            />
            <YAxis tickLine={false} axisLine={false} />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  indicator="line"
                  labelFormatter={(value) => {
                    let hour = parseInt(value)
                    let amPm = hour >= 12 ? 'p.m.' : 'a.m.'
                    hour = hour % 12
                    hour = hour ? hour : 12
                    return `${hour} ${amPm}`
                  }}
                />
              }
            />
            <Area
              dataKey="accidents"
              type="monotone"
              fill="var(--color-accidents)"
              fillOpacity={0.4}
              stroke="var(--color-accidents)"
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="text-sm italic text-muted-foreground">
        Data collected from the Arizona Department of Transportation (ADOT) via a public records
        request.
      </CardFooter>
    </Card>
  )
}
